// Generated by Framer (46f1e3d)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const cycleOrder = ["aIdw2jWKj", "MBt378KL3"];

const serializationHash = "framer-OFEo1"

const variantClassNames = {aIdw2jWKj: "framer-v-q4nnah", MBt378KL3: "framer-v-mgf473"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableEnumMap = {Bold: "bold", Duotone: "duotone", Fill: "fill", Light: "light", Regular: "regular", Thin: "thin"}

const humanReadableVariantMap = {Dark: "aIdw2jWKj", Light: "MBt378KL3"}

const getProps = ({height, iconColor, iconDark, iconLight, id, weight, width, ...props}) => { return {...props, AeO5PLd9q: iconColor ?? props.AeO5PLd9q ?? "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28))", fle84VlOx: iconLight ?? props.fle84VlOx ?? "Sun", njtQOvFbC: humanReadableEnumMap[weight] ?? weight ?? props.njtQOvFbC ?? "bold", pdO6Nm4lI: iconDark ?? props.pdO6Nm4lI ?? "Moon", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "aIdw2jWKj"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;iconColor?: string;iconDark?: string;iconLight?: string;weight?: keyof typeof humanReadableEnumMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AeO5PLd9q, pdO6Nm4lI, fle84VlOx, njtQOvFbC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "aIdw2jWKj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-q4nnah", className, classNames)} data-framer-name={"Dark"} layoutDependency={layoutDependency} layoutId={"aIdw2jWKj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({MBt378KL3: {"data-framer-name": "Light"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-a6udmr-container"} layoutDependency={layoutDependency} layoutId={"haOLfQbnl-container"}><Phosphor color={AeO5PLd9q} height={"100%"} iconSearch={"House"} iconSelection={pdO6Nm4lI} id={"haOLfQbnl"} layoutId={"haOLfQbnl"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={njtQOvFbC} width={"100%"} {...addPropertyOverrides({MBt378KL3: {iconSelection: fle84VlOx}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-OFEo1.framer-1jdgh3f, .framer-OFEo1 .framer-1jdgh3f { display: block; }", ".framer-OFEo1.framer-q4nnah { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; padding: 0px; position: relative; width: 40px; }", ".framer-OFEo1 .framer-a6udmr-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-OFEo1.framer-q4nnah { gap: 0px; } .framer-OFEo1.framer-q4nnah > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-OFEo1.framer-q4nnah > :first-child { margin-left: 0px; } .framer-OFEo1.framer-q4nnah > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 40
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"MBt378KL3":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"AeO5PLd9q":"iconColor","pdO6Nm4lI":"iconDark","fle84VlOx":"iconLight","njtQOvFbC":"weight"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameraRyNo51n7: React.ComponentType<Props> = withCSS(Component, css, "framer-OFEo1") as typeof Component;
export default FrameraRyNo51n7;

FrameraRyNo51n7.displayName = "Single";

FrameraRyNo51n7.defaultProps = {height: 40, width: 40};

addPropertyControls(FrameraRyNo51n7, {variant: {options: ["aIdw2jWKj", "MBt378KL3"], optionTitles: ["Dark", "Light"], title: "Variant", type: ControlType.Enum}, AeO5PLd9q: {defaultValue: "var(--token-fa6255fa-1747-4bc3-894a-8375bc25f0bd, rgb(28, 28, 28)) /* {\"name\":\"font/active\"} */", title: "IconColor", type: ControlType.Color}, pdO6Nm4lI: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Moon", description: undefined, hidden: undefined, title: "IconDark"}, fle84VlOx: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "Sun", description: undefined, hidden: undefined, title: "IconLight"}, njtQOvFbC: PhosphorControls?.["weight"] && {...PhosphorControls["weight"], defaultValue: "bold", description: undefined, hidden: undefined, title: "Weight"}} as any)

addFonts(FrameraRyNo51n7, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})